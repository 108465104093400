import styles from './gallery.module.scss';
import { motion } from 'framer-motion';

export default function Gallery({ mousePosition, handle }) {
  /* Si quitas los comentarios la imagen de la galería sigue al ratón */
  /* const { x, y } = mousePosition; */

  return (
    <div className={styles.gallery}>
      <div className={styles.imageContainer}>
        <img
          src={`/assets/ProjectImagesFolder/${handle}/background.jpg`}
          alt="background"
          className={styles.image}
        />
      </div>

      <motion.div
        className={styles.vignette}
        /* style={{ x, y }} */
      >
        <img
          src={`/assets/ProjectImagesFolder/${handle}/1.jpg`}
          alt="gallery"
          className={styles.image}
        />
      </motion.div>
    </div>
  );
}
