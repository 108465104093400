import { useState, useEffect } from 'react';
import TarjetaSola from './TarjetaSola/TarjetaSola';
import styles from './tarjetaslayer.module.css';

export default function TarjetasLayer() {
  const [showGifs, setShowGifs] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowGifs(window.innerWidth > 500); // Cambia a false si el ancho es menor o igual a 800px
    };

    window.addEventListener('resize', handleResize);

    // Ejecuta la función de inmediato para establecer el valor inicial
    handleResize();

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <TarjetaSola
        className={styles.tarjeta}
        number="1"
        title="CREA IMPACTO"
        text="Destaca online y aumenta tu presencia digital con un diseño a tu altura"
        gifUrl={'/assets/gifs/inifinity.gif'}
        showGif={showGifs}
      />
      <TarjetaSola
        className={styles.tarjeta}
        number="2"
        title="ANALIZA"
        text="Obten herramientas personalizadas capaces de analizar y clasificar datos con la finalidad de optimizar y mejorar tu negocio."
        gifUrl={'/assets/gifs/black_hole.gif'}
        showGif={showGifs}
      />
      <TarjetaSola
        className={styles.tarjeta}
        number="3"
        title="MEJORA"
        text="Actualiza tu Stack tecnologico para reducir costes y aumentar rendimiento al mismo tiempo!"
        gifUrl={'/assets/gifs/world.gif'}
        showGif={showGifs}
      />
      <TarjetaSola
        className={styles.tarjeta}
        number="4"
        title="CRECE"
        text="Encuentra nuevos clientes gracias al marketing digital, fideliza por la calidad del servicio."
        gifUrl={'/assets/gifs/donut.gif'}
        showGif={showGifs}
      />
      <TarjetaSola
        className={styles.tarjeta}
        number="5"
        title="AHORRA"
        text="Automatiza papeleo, excels, chats y una infinidad de procesos mas"
        gifUrl={'/assets/gifs/numbers.gif'}
        showGif={true} // El GIF de la última tarjeta siempre se muestra
      />
    </div>
  );
}
