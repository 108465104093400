import styles from './tarjetasola.module.css';

export default function TarjetaSola(props) {
  const { title, text, gifUrl, number, showGif } = props;

  return (
    <div
      className={styles.tarjeta}
      style={{ position: 'sticky', top: `${10 + (number - 1) * 2}vh`, zIndex: number }}
    >
      <div className={styles.row}>
        <div>
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
        <div
          className={styles.gif__container}
          style={{ display: showGif ? 'block' : 'none' }} // Controla la visibilidad del GIF
        >
          <img src={gifUrl} alt="GIF" className={styles.gif} />
        </div>
      </div>
    </div>
  );
}
