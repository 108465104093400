import styles from './nameband.module.css';

/*
props:
  src: url
    image that will appear to the left of the person intro
  post : string
    post of the preson
 name : name
    name of the person
*/

export default function NameBand(props) {
  const { align = 'izq', src, post, name } = props;
  const alignmentClass = align === 'dch' ? styles.dch : styles.izq;

  return (
    <div className={alignmentClass}>
      <div className={styles.image}>
        <img src={src} alt="name nand " />
      </div>
      <div className={styles.text}>
        <div className={styles.text__post}>
          <p>{post}</p>
        </div>
        <div className={styles.text__name}>
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
}
