import React from 'react';
import styles from './style.module.scss';
import { height, background, mountAnim } from '../anim';
import { motion } from 'framer-motion';

export default function Stairs({ number = 5}) {
  // Por defecto, el número de escaleras es 5 si no se pasa la prop `number`.

  return (
    <div className={styles.stairs}>
      {
        [...Array(number)].map((_, index) => {
          const width = `${100 / number}vw`; // Calcula el ancho de cada Stair en función del número total.
          return <Stair key={index} index={index} style={{ width }} />;
        })
      }
      <Background />
    </div>
  );
}

const Stair = ({ index, style }) => {
  return (
    <motion.div
      variants={height}
      {...mountAnim}
      custom={4 - index}
      className={styles.stair}
      style={style} // Aplica el estilo de ancho calculado.
    />
  );
};

const Background = () => {
  return (
    <motion.div
      variants={background}
      {...mountAnim}
      className={styles.background}
    />
  );
};
