import { motion } from 'framer-motion';
import { opacity, slideLeft, mountAnim } from '../anim';
import styles from './style.module.scss';
import LinkComponent from './link';
import { useEffect } from 'react';

export default function Index({ scrollToHeaderAndHover, scrollToContactForm, scrollToProjectSection, closeMenu }) {
  const menu = [
    {
      title: "HOME",
      description: "HOME",
      onClick: scrollToHeaderAndHover
    },
    {
      title: "CONTACTO",
      description: "HABLAMOS",
      onClick: scrollToContactForm
    },
    {
      title: "PROYECTOS",
      description: "CREAMOS",
      onClick: scrollToProjectSection
    }
  ];

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closeMenu();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className={styles.menu}>
      <div className={styles.header}>
        <motion.svg
          variants={slideLeft}
          {...mountAnim}
          onClick={() => closeMenu()} // Si tienes `closeMenu` implementado
          width="68"
          height="68"
          viewBox="0 0 68 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 1.5L67 67" stroke="white"/>
          <path d="M66.5 1L0.999997 66.5" stroke="white"/>
        </motion.svg>
      </div>
      <div className={styles.body}>
        {
          menu.map((el, index) => (
            <LinkComponent data={el} index={index} key={index} />
          ))
        }
      </div>
      <motion.div
        variants={opacity}
        {...mountAnim}
        custom={0.5}
        className={styles.footer}>
      </motion.div>
    </div>
  );
}
