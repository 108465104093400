import './App.css';
import Header from './components/Header/Header';
import ViscoHover from './components/ViscoHover/ViscoHover';
import ContactForm from './components/ContactForm/ContactForm';
import Rectangles from './components/Rectangles/Rectangles';
import Footer from './components/Footer/Footer';
import TarjetasLayer from './components/TarjetasLayer/TarjetasLayer';
import NameBands from './components/NameBand/NameBands';
import { useRef, useState } from 'react';
import InfiniteLooper from './components/InfiniteLooper/InfiniteLooper';
import ParticleBackground from 'react-particle-backgrounds';
import SlidingStairs from './components/SlidingStairs';
import ProjectSection from './components/ProjectSection/ProjectSection'
import Menu from './components/Menu/Menu'

function App() {
  const contactFormRef = useRef(null);
  const headerAndHoverRef = useRef(null);
  const projectSectionRef = useRef(null);

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToHeaderAndHover = () => {
    if (headerAndHoverRef.current) {
      headerAndHoverRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToProjectSection = () => {
    if (projectSectionRef.current) {
      projectSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const settings = {
    canvas: {
      canvasFillSpace: true,
      useBouncyWalls: true
    },
    particle: {
      particleCount: 200,
      color: 'white',
      minSize: 2,
      maxSize: 3
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 2,
      maxSpeed: 3
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 0
    }
  };

  return (
    <main className="main">
      {/*
        <div className='menu'>
          <Menu onScrollToContact={scrollToContactForm}
            onScrollToHome={scrollToHeaderAndHover}
            projects={projects} />
        </div>       */}
      <SlidingStairs
        scrollToHeaderAndHover={scrollToHeaderAndHover}
        scrollToContactForm={scrollToContactForm}
        scrollToProjectSection={scrollToProjectSection}
      />
      <div className='notMenu'>
        <div ref={headerAndHoverRef} className="headerAndHover">
          <Header/>
          <ViscoHover />
          <ParticleBackground className="particle__background" settings={settings} />
        </div>
        <div>
          <InfiniteLooper palabra1={"VISIONARY"} palabra2={"CONSULTING"}/>
        </div>
        <div className="LoopTarjetasRectangles">
          <TarjetasLayer />
          <div style={{ height: '10rem' }}></div>
          <Rectangles />
        </div>
        <div style={{ height: '10rem' }}></div>
        <InfiniteLooper palabra1={"NUESTROS"} palabra2={"PROYECTOS"}/>
        <div className="projectSection" ref={projectSectionRef}>
          <ProjectSection/>
        </div>
        <InfiniteLooper palabra1={"CREAMOS"} palabra2={"IMPACTO"}/>
        <div className="nosotros" style={{ marginBottom: '5vh' , marginTop: '200px'}}>
          <h1>NOSOTROS</h1>
        </div>
        <NameBands />
        <div style={{ height: '10rem' }}></div>
        <div ref={contactFormRef}>
          <ContactForm introMessage="¿Miedo al compromiso?" />
        </div>
        <Footer />
      </div>

    </main>
  );
}

export default App;
