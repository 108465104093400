import styles from './style.module.scss';

export default function ({openMenu}) {

    return (
        <div onClick={() => {openMenu()}} className={styles.button}>
            <div className={styles.background}></div>
                <img src='assets/menu-svgrepo-com.svg' alt='burger'/>
        </div>
    )
}