import styles from './viscohover.module.css';
import { useState, useEffect } from 'react';

export default function ViscoHover() {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  const responsiveHeight = () => {
    if (windowWidth <= 2000 && windowWidth >= 1500) {
      return windowHeight * 0.08;
    } else if (windowWidth < 1500 && windowWidth >= 1000) {
      return windowHeight * 0.06;
    } else return 0;
  };

  const [circlePos, setCirclePos] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setCirclePos({ x, y });
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsHovering(true)}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img src="/assets/ViscoWhite.svg" alt="VISCO" className={styles.image__default} />
      {isHovering && (
        <div
          className={styles.circle}
          style={{
            left: `${circlePos.x}px`,
            top: `${circlePos.y}px`,
          }}
        ></div>
      )}
      <img
        src="/assets/ViscoPixel.svg"
        alt="VISCO"
        className={styles.image__hover}
        style={
          isHovering
            ? { clipPath: `circle(100px at ${circlePos['x']}px ${circlePos['y'] - responsiveHeight()}px)` }
            : { display: 'none' }
        }
      />
    </div>
  );
}
