import NameBand from './NameBand';
import stlyes from './nameband.module.css';

export default function NameBands(props) {
  return (
    <div className={stlyes.container}>
      <div className={stlyes.content}>
        <NameBand align="izq" src="assets/Sword.svg" post="Director ejecutivo" name="VLAD DINCA" />
        <NameBand align="dch" src="assets/Diamond.svg" post="Marketing y redes" name="MARCO ZÚÑIGA" />
        <NameBand align="izq" src="assets/Heart.svg" post="Diseño y creatividad" name="ELISA MARCO" />
        <NameBand align="dch" src="assets/Bolt.svg" post="Desarrollo front end" name="JORGE PASCUAL" />
        <NameBand align="izq" src="assets/PixelStar.svg" post="Ventas y estrategia" name="NIKOLS MORA" />
        <NameBand align="dch" src="assets/Shield.svg" post="E-commerce y gestión digital" name="AXEL VICENTE" />
      </div>
      <div className={stlyes.circle}></div>
    </div>
  );
}
