import styles from './style.module.scss';
import { motion } from 'framer-motion';
import { mountAnim, rotateX } from '../anim';
import { useRef, useState, useEffect } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';

export default function LinkComponent({ data, index }) {
  const { title, description, onClick } = data;
  const outer = useRef(null);
  const inner = useRef(null);

  // Estado para guardar si el ancho es menor a 1200px
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  useEffect(() => {
    // Función para actualizar el estado según el tamaño de la ventana
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    // Agrega un event listener para el cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpia el event listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const manageMouseEnter = (e) => {
    const bounds = e.target.getBoundingClientRect();
    if (e.clientY < bounds.top + bounds.height / 2) {
      gsap.set(outer.current, { top: "-100%" });
      gsap.set(inner.current, { top: "100%" });
    } else {
      gsap.set(outer.current, { top: "100%" });
      gsap.set(inner.current, { top: "-100%" });
    }
    gsap.to(outer.current, { top: "0%", duration: 0.3 });
    gsap.to(inner.current, { top: "0%", duration: 0.3 });
  };

  const manageMouseLeave = (e) => {
    const bounds = e.target.getBoundingClientRect();
    if (e.clientY < bounds.top + bounds.height / 2) {
      gsap.to(outer.current, { top: "-100%", duration: 0.3 });
      gsap.to(inner.current, { top: "100%", duration: 0.3 });
    } else {
      gsap.to(outer.current, { top: "100%", duration: 0.3 });
      gsap.to(inner.current, { top: "-100%", duration: 0.3 });
    }
  };

  // Función de click ajustada a la pantalla
  const handleClick = () => {
    if (isSmallScreen) {
      onClick(); // Primer click
      onClick(); // Segundo click
    } else {
      onClick(); // Solo un click
    }
  };

  return (
    <motion.div
      onClick={handleClick}
      onMouseEnter={manageMouseEnter}
      onMouseLeave={manageMouseLeave}
      variants={rotateX}
      {...mountAnim}
      custom={index}
      className={styles.el}
    >
      <Link to="/">{title}</Link>
      <div ref={outer} className={styles.outer}>
        <div ref={inner} className={styles.inner}>
          {[...Array(2)].map((_, index) => (
            <div key={index} onClick={handleClick} className={styles.container}>
              <p>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}
