import styles from './privacypolicy.module.css';
import { useState, useEffect } from 'react';

export default function PrivacyPolicy() {
  const PopUp = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className={styles.overlay}>
        <div className={styles.content}>
          <div className={styles.content__images}>
            <img src="assets/ThreeStars.svg" alt="visco logo" />
            <img src="assets/Cross.svg" alt="literaly a cross" className={styles.overlay__closer} onClick={onClose} />
          </div>
          <div className={styles.content__text}>
            <h1>POLÍTICA DE PRIVACIDAD</h1> <br />
            <p>Responsable del tratamiento de sus datos:</p>
            <br />
            <ul className={styles.lista}>
              <li>
                <p>
                  <b>Identidad:</b> VISIONARY CONSULTING S.L.
                </p>
              </li>
              <li>
                <p>
                  <b>NIF:</b> B19362235
                </p>
              </li>
              <li>
                <p>
                  <b>Dirección postal:</b> C/ Venecia 24, Local Derecha, 50017, Zaragoza.{' '}
                </p>
              </li>
              <li>
                <p>
                  <b>Teléfono:</b> 622 427 441
                </p>
              </li>
              <li>
                <p>
                  <b>Correo electrónico:</b> hola@visco.uno
                </p>
              </li>
            </ul>
            <br />
            <p>
              <b>Finalidad:</b> Prestar los servicios solicitados, mantener comunicaciones para la consecución de los
              mismos y mantenerle informado sobre nuestra actividad general.
            </p>
            <br />
            <p>
              <b>Legitimación:</b> Ejecución de un contrato. Consenitimiento del intereasdo.
            </p>
            <br />
            <p>
              <b>Destinatarios:</b> No se cederán datos a terceros, salvo obligación legal.
            </p>
            <br />
            <p>
              <b>Derechos: </b>Tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos
              indicados en la información adicional, que puede ejercer dirigiéndose a la dirección hola@visco.uno
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2>¿Con qué finalidad tratamos sus datos personales?</h2> <br />
            <p>
              En VISIONARY CONSULTING, S.L. tratamos la información que nos facilitan las personas interesadas con el
              fin de prestarle los servicios concertados, realizar la gestión administrativa, contable y fiscal de los
              servicios solicitados, mantener comunicaciones acerca de los mismos, así como el envío de comunicaciones
              inclusive por correo electrónico, para mantenerle informado sobre nuestra actividad en general.
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2>¿Por cuánto tiempo conservaremos sus datos?</h2> <br />
            <p>
              Los datos se conservarán mientras no solicite su supresión, y en cualquier caso, durante los años
              necesarios para cumplir con las obligaciones legales.
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2>¿Cuál es la legitimación para el tratamiento de sus datos?</h2> <br />
            <p>Le indicamos la base legal para el tratamiento de sus datos:</p>
            <br />
            <ul className={styles.lista}>
              <li>
                <p>
                  <b>Ejecución de un contrato:</b> Prestación de los servicios solicitados.
                </p>
              </li>
              <li>
                <p>
                  <b>Consentimiento del interesado:</b> Envío de comunicaciones comerciales sobre nuestra actividad en
                  general.
                </p>
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <h2>¿A qué destinatarios se comunicarán sus datos?</h2> <br />
            <p>No se cederán datos a terceros, salvo obligación legal.</p>
            <br />
            <br />
            <br />
            <br />
            <h2>¿Transferencias de datos a terceros países?</h2> <br />
            <p>No están previstas transferencias de datos a terceros países.</p>
            <br />
            <br />
            <br />
            <br />
            <h2>¿Cuáles son sus derechos cuando nos facilita sus datos?</h2> <br />
            <p>
              Cualquier persona tiene derecho a obtener confirmación sobre si en VISIONARY CONSULTING, S.L. estamos
              tratando datos personales que les concierne, o no.
            </p>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  /* CLOSE POPUP PRESSING ESC KEY */

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClosePopup();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <div>
        <p className={styles.overlay__trigger} onClick={handleOpenPopup}>
          Política de privacidad
        </p>
        <PopUp show={showPopup} onClose={handleClosePopup} />
      </div>
    </>
  );
}
