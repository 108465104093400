import styles from './projectsection.module.scss'
import Gallery from './Gallery/Gallery';
import Description from './Description/Description';
import { useEffect } from 'react';
import Lenis from '@studio-freight/lenis';
import { useSpring } from 'framer-motion';

const projects = [
  { name: "Dyal Thak", handle: "dyal_thak" },
  { name: "Leidinger Matthias", handle: "leidinger_matthias" },
  { name: "Mark Rammers", handle: "mark_rammers" },
  { name: "Landon Speers", handle: "landon_speers" }
];

export default function ProjectSection() {
  const spring = { stiffness: 150, damping: 15, mass: 0.1 };
  const mousePosition = {
    x: useSpring(0, spring),
    y: useSpring(0, spring)
  };

  const mouseMove = (e) => {
    const { clientX, clientY } = e;
    const targetX = clientX - (window.innerWidth / 2 * 0.25);
    const targetY = clientY - (window.innerWidth / 2 * 0.30);
    mousePosition.x.set(targetX);
    mousePosition.y.set(targetY);
  };

  useEffect(() => {
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);

  return (
    <main onMouseMove={mouseMove} className={styles.main}>
      {projects.map(({ handle }, i) => (
        <Gallery mousePosition={mousePosition} handle={handle} key={i} />
      ))}
      {/* Zona de texto con imagen on hover
      <div className={styles.gallery}>
        <Description mousePosition={mousePosition} projects={projects} />
      </div>
      */}
    </main>
  );
}
