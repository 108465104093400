import { useRef, useEffect, useState } from 'react';
import styles from './contactform.module.css';
import emailjs from 'emailjs-com';
import LegalTerms from '../LegalTerms/LegalTerms';

export default function ContactForm() {
  /* Text Area controls */
  const [responsiveText, setResponsiveText] = useState('');
  const [rowNumber, setRowNumber] = useState(1); // Valor inicial

  useEffect(() => {
    const updateRowNumber = () => {
      if (window.innerWidth < 450) {
        setRowNumber(3);
      } else if (window.innerWidth >= 450 && window.innerWidth <= 830) {
        setRowNumber(2);
      } else {
        setRowNumber(1);
      }
    };

    // Ejecuta la función al cargar el componente
    updateRowNumber();

    // Agrega el event listener para escuchar los cambios de tamaño
    window.addEventListener('resize', updateRowNumber);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateRowNumber);
    };
  }, []);

  useEffect(() => {
    const updateResponsiveText = () => {
      if (window.innerWidth > 580) {
        setResponsiveText('He leído, entendido y acepto los   ');
      } else {
        setResponsiveText('');
      }
    };

    // Inicializa el texto al cargar la página
    updateResponsiveText();

    // Añade un listener para actualizar cuando cambia el tamaño de la pantalla
    window.addEventListener('resize', updateResponsiveText);

    return () => {
      window.removeEventListener('resize', updateResponsiveText);
    };
  }, []);
  useEffect(() => {
    const updateResponsiveText = () => {
      if (window.innerWidth > 580) {
        setResponsiveText('He leído, entendido y acepto los   ');
      } else {
        setResponsiveText('');
      }
    };

    // Inicializa el texto al cargar la página
    updateResponsiveText();

    // Añade un listener para actualizar cuando cambia el tamaño de la pantalla
    window.addEventListener('resize', updateResponsiveText);

    return () => {
      window.removeEventListener('resize', updateResponsiveText);
    };
  }, []);

  const textareaRef = useRef(null);
  const minHeight = '2rem';

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.value ? textarea.scrollHeight + 'px' : minHeight;
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('input', adjustHeight);
      return () => {
        textarea.removeEventListener('input', adjustHeight);
      };
    }
  }, []);

  /* Phone number */
  const [valor, setValor] = useState('');
  const handleValorChange = (e) => {
    const nuevoValor = e.target.value;
    if (/^\d*$/.test(nuevoValor)) {
      setValor(nuevoValor);
    }
  };

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    userMessage: '',
  });

  /* Submit control */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isChecked) {
      alert('Debes aceptar los términos y condiciones.');
      return;
    }

    emailjs
      .send(
        'service_dkvde2c',
        'template_nm9aiok',
        {
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          message: formData.userMessage,
        },
        'i5IAd9fOFv_YSwLzT'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>¿Miedo al compromiso?</h1>
      </div>
      <div className={styles.row}>
        <div className={styles.row__mail}>
          <a href="mailto:3D@visco.uno">hola@visco.uno</a>
        </div>
        <div className={styles.row__message}>
          <p>
            Primera consulta gratuita. <img src="assets/BlueCircle.svg" alt="Circulito Azul" /> Dinos qué necesitas y te
            explicaremos cómo podemos hacerlo posible.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.form__name}>
          <input
            type="text"
            name="fullName"
            placeholder="Nombre y apellidos"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.form__row}>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="phone"
              placeholder="Teléfono"
              value={valor}
              onChange={handleValorChange}
              required
            />
          </div>
        </div>
        <div className={styles.form__userMessage}>
          <textarea
            name="userMessage"
            placeholder="Aquí puedes contarnos tu idea (o decirnos algo bonito)"
            ref={textareaRef}
            value={formData.userMessage}
            onChange={handleChange}
            rows={rowNumber}
            required
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.footer__legalTerms}>
            <input type="checkbox" id="customCheckbox" checked={isChecked} onChange={handleCheckboxChange} required />
            <label htmlFor="customCheckbox">
              {responsiveText} &#8204;
              <LegalTerms />.
            </label>
          </div>
          <div className={styles.footer__button}>
            <button type="submit">Envía</button>
          </div>
        </div>
      </form>
    </div>
  );
}
