import { useState } from 'react'
import Burger from './burger';
import { AnimatePresence } from 'framer-motion';
import Stairs from './stairs'
import Menu from './menu'

export default function SlidingStaris({scrollToHeaderAndHover, scrollToContactForm, scrollToProjectSection}) {

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const delay = 200;

  const handleScrollToContactForm = () => {
    scrollToContactForm();
    setTimeout(() => {
     setMenuIsOpen(false);
    }, delay)
  }

  const handleScrollToHeaderAndHover = () => {
    scrollToHeaderAndHover();
    setTimeout(() => {
        setMenuIsOpen(false);
       }, delay)
  }

  const handleScrollToProjectSection = () => {
    scrollToProjectSection();
    setTimeout(() => {
      setMenuIsOpen(false);
     }, delay)
  }

  return (
      <div>
          <Burger openMenu={() => {setMenuIsOpen(true)}}/>
          <AnimatePresence mode="wait">
              {
                  menuIsOpen && <>
                  <Stairs number={5}/>
                  <Menu closeMenu={() => {setMenuIsOpen(false)}} scrollToContactForm={handleScrollToContactForm} scrollToHeaderAndHover={handleScrollToHeaderAndHover} scrollToProjectSection={handleScrollToProjectSection} />
                  </>
              }
          </AnimatePresence>
      </div>
  )
}