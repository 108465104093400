import React, { useRef, useEffect, useState } from 'react';
import styles from './rectangles.module.css';

export default function Rectangles() {
  // Absolute position (not counting scroll) of text divs - except for the middle one
  const textLeftRef = useRef(null);
  const textRightRef = useRef(null);

  const [leftTextCoords, setLeftTextCoords] = useState(null);
  const [rightTextCoords, setRightTextCoords] = useState(null);

  // Absolute position (not counting scroll) of img divs
  const imgLeftRef = useRef(null);
  const imgRightRef = useRef(null);

  const [leftImgCoords, setLeftImgCoords] = useState(null);
  const [rightImgCoords, setRightImgCoords] = useState(null);

  //Middle div text-img container absolute position

  const divReversedRef = useRef(null);
  const [reversedDivCoords, setReversedDivCoords] = useState(null);

  // Function to update the offsets of both text and image elements
  const updateOffsets = () => {
    // Update text coordinates
    if (textLeftRef.current && textRightRef.current) {
      setLeftTextCoords({
        top: textLeftRef.current.offsetTop,
        left: textLeftRef.current.offsetLeft,
        width: textLeftRef.current.offsetWidth,
        height: textLeftRef.current.offsetHeight,
      });

      setRightTextCoords({
        top: textRightRef.current.offsetTop,
        left: textRightRef.current.offsetLeft,
        width: textRightRef.current.offsetWidth,
        height: textRightRef.current.offsetHeight,
      });
    }

    // Update image coordinates
    if (imgLeftRef.current && imgRightRef.current) {
      setLeftImgCoords({
        top: imgLeftRef.current.offsetTop,
        left: imgLeftRef.current.offsetLeft,
        width: imgLeftRef.current.offsetWidth,
        height: imgLeftRef.current.offsetHeight,
      });

      setRightImgCoords({
        top: imgRightRef.current.offsetTop,
        left: imgRightRef.current.offsetLeft,
        width: imgRightRef.current.offsetWidth,
        height: imgRightRef.current.offsetHeight,
      });
    }

    // Udate middle div coordinates

    if (divReversedRef.current) {
      setReversedDivCoords({
        top: divReversedRef.current.offsetTop,
        left: divReversedRef.current.offsetLeft,
        width: divReversedRef.current.offsetWidth,
        height: divReversedRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    // Calculate the initial coordinates when the component mounts
    updateOffsets();

    // Add an event listener to recalculate coordinates when the window is resized
    window.addEventListener('resize', updateOffsets);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateOffsets);
    };
  }, []);

  //Responsive values for the starting position of the first curve

  const getStartingLeftCoordValueX = () => {
    if (window.innerWidth <= 1200) {
      return leftImgCoords.left + leftImgCoords.width / 2;
    } else {
      return leftImgCoords.left + leftImgCoords.width + leftTextCoords.width * 1.25;
    }
  };

  const getStartingLeftCoordValueY = () => {
    if (window.innerWidth <= 1200) {
      return leftTextCoords.top + leftTextCoords.height * 1.5;
    } else {
      return leftTextCoords.top + leftTextCoords.height / 2;
    }
  };

  //Responsive values for the ending position of the first curve

  const getEndingLeftCoordValueX = () => {
    if (window.innerWidth <= 1200) {
      return reversedDivCoords.left + reversedDivCoords.width / 4;
    } else {
      return reversedDivCoords.left + reversedDivCoords.width / 2;
    }
  };

  const getEndingLeftCoordValueY = () => {
    if (window.innerWidth <= 1200) {
      return reversedDivCoords.top + reversedDivCoords.height / 4;
    } else {
      return reversedDivCoords.top - reversedDivCoords.height / 4;
    }
  };

  //Responsive values for the curvature of the first curve

  const getLeftCurvatureX = () => {
    if (window.innerWidth <= 1200) {
      return (leftImgCoords.left + reversedDivCoords.left) * 0.4;
    } else {
      return (leftImgCoords.left + reversedDivCoords.left) * 0.9;
    }
  };

  const getLeftCurvatureY = () => {
    if (window.innerWidth <= 1200) {
      return (leftImgCoords.top + reversedDivCoords.top) * 0.7;
    } else {
      return (leftImgCoords.top + reversedDivCoords.top) * 0.3;
    }
  };

  //Responsive values for the starting position of the second curve

  const getStartingRightCoordValueX = () => {
    if (window.innerWidth <= 1200) {
      return reversedDivCoords.left + reversedDivCoords.width * 0.8;
    } else {
      return reversedDivCoords.left + reversedDivCoords.width / 2;
    }
  };

  const getStartingRightCoordValueY = () => {
    if (window.innerWidth <= 1200) {
      return reversedDivCoords.top + reversedDivCoords.height / 4;
    } else {
      return reversedDivCoords.top + reversedDivCoords.height * 1.25;
    }
  };

  //Responsive values for the ending position of the second curve

  const getEndingRightCoordValueX = () => {
    if (window.innerWidth <= 1200) {
      return rightImgCoords.left + rightImgCoords.width / 2;
    } else {
      return rightImgCoords.left - rightTextCoords.width * 1.1;
    }
  };

  const getEndingRightCoordValueY = () => {
    if (window.innerWidth <= 1200) {
      return rightImgCoords.top - rightImgCoords.height / 2;
    } else {
      return rightImgCoords.top + rightImgCoords.height / 2;
    }
  };

  //Responsive values for the curvature of the second curve

  const getRightCurvatureX = () => {
    if (window.innerWidth <= 1200) {
      return (reversedDivCoords.left + rightImgCoords.left) * 0.8;
    } else {
      return (reversedDivCoords.left + rightImgCoords.left) * 0.4;
    }
  };

  const getRightCurvatureY = () => {
    if (window.innerWidth <= 1200) {
      return (reversedDivCoords.top + rightImgCoords.top) * 0.4;
    } else {
      return (reversedDivCoords.top + rightImgCoords.top) * 0.7;
    }
  };

  return (
    <>
      <div className={styles.parent__container}>
        <div className={styles.container__rectangles}>
          <div className={styles.rectangle}>
            <div className={styles.rectangle__content__left}>
              <div>
                <img style={{ marginRight: '1vw' }} ref={imgLeftRef} src="assets/WhiteCircle.svg" alt="white circle" />
              </div>
              <div className={styles.rectangle__text}>
                {/* Text reference */}
                <p ref={textLeftRef}>hablamos</p>
              </div>
            </div>
          </div>

          <div className={styles.rectangle__reversed}>
            <div ref={divReversedRef} className={styles.rectangle__reversed__content}>
              <div>
                <img src="assets/HollowCircle.svg" style={{ marginBottom: '2vh' }} alt="white circle" />
              </div>
              <div className={styles.rectangle__text}>
                {/* Text reference */}
                <p>diseñamos</p>
              </div>
            </div>
          </div>

          <div className={styles.rectangle}>
            <div className={styles.rectangle__content__right}>
              <div className={styles.rectangle__text}>
                {/* Text reference */}
                <p ref={textRightRef}>solucionamos</p>
              </div>
              <div>
                <img style={{ marginLeft: '1vw' }} ref={imgRightRef} src="assets/WhiteCircle.svg" alt="white circle" />
              </div>
            </div>
          </div>
        </div>

        {/* Curves SVG */}
        <div className={styles.container__curves}>
          {/* Curve between the left image and the reversed image */}
          {leftImgCoords && reversedDivCoords && (
            <svg className={styles.curve}>
              <path
                d={`M ${getStartingLeftCoordValueX()} ${getStartingLeftCoordValueY()}
                   Q ${getLeftCurvatureX()} ${getLeftCurvatureY()},
                   ${getEndingLeftCoordValueX()} ${getEndingLeftCoordValueY()}`}
                stroke="white" // Different color for image curve
                fill="transparent"
                strokeWidth="2"
              />
            </svg>
          )}

          {/* Curve between the reversed image and the right image */}
          {reversedDivCoords && rightImgCoords && (
            <svg className={styles.curve}>
              <path
                d={`M ${getStartingRightCoordValueX()} ${getStartingRightCoordValueY()}
                   Q ${getRightCurvatureX()} ${getRightCurvatureY()},
                   ${getEndingRightCoordValueX()}
                    ${getEndingRightCoordValueY()}`}
                stroke="white" // Different color for image curve
                fill="transparent"
                strokeWidth="2"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
}
