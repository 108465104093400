import LegalTerms from '../LegalTerms/LegalTerms';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import styles from './footer.module.css';
import { useState, useEffect } from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear(); // Obtener el año actual
  const [marginValue, setMarginValue] = useState(0);

  useEffect(() => {
    const updateMarginValue = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 820) {
        setMarginValue(screenWidth * 0.04);
      } else {
        setMarginValue(0);
      }
    };

    // Ejecutar la primera vez cuando carga la página
    updateMarginValue();

    // Escuchar cambios en el tamaño de la ventana
    window.addEventListener('resize', updateMarginValue);

    // Limpiar el listener cuando el componente se desmonte
    return () => window.removeEventListener('resize', updateMarginValue);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.header__image}>
            <img src="assets/VISCOVisionaryConsultants.svg" alt="Amazing visco header" />
          </div>
          <div className={styles.header__text}>
            <p className={styles.header__text__message}> Aquí es donde ponemos nuestro contacto y tú nos stalkeas :)</p>
            <div className={styles.header__text__contact}>
              <p>C/ Venecia 24, 50007 Zaragoza</p>
              <p>
                +34 667 72 37 19 |&#8204;<a href="mailto:3D@visco.uno"> hola@visco.uno</a>
              </p>
              <p>
                <a href="https://www.instagram.com/team_visco/">Instagram</a>{' '}
                <a href="https://www.linkedin.com/company/viscoteam/">LinkedIn</a>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footer__popUps}>
            <div>
              <p style={{ marginRight: marginValue }}>
                <PrivacyPolicy />
              </p>
            </div>
            <div>
              <p>
                <LegalTerms />
              </p>
            </div>
          </div>
          <div className={styles.footer__year}>
            <p>&copy; Visco {currentYear} | Crafted with CORAZON</p>
          </div>
        </div>
      </div>
    </>
  );
}
