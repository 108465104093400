import styles from './infiniteLooper.module.css';

export default function InfiniteLooper({palabra1, palabra2}) {
  return (
    <div
      className={styles.slider}
      style={{
        '--width': '67rem',
        '--height': '10rem',
        '--quantity': 4,
      }}
    >
      <div className={styles.list}>
        {[...Array(10)].map((_, index) => (
          <div
            key={index}
            className={styles.item}
            style={{ '--position': index + 1 }}
          >
            <div className={styles.content}>
              <h1>{palabra1}</h1>
              <img className={styles.image} src='/assets/images/toroide.png' alt='toroidal figure'></img>
              <h1>{palabra2}</h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
