import styles from './header.module.css';
import { useState, useEffect } from 'react';


export default function Header() {
  const [showButtons, setShowButtons] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isSmallScreen) {
      setShowButtons(true);
    }
  }, [isSmallScreen]);

  const handleClick = () => {
    if (isSmallScreen) {
      setShowButtons(!showButtons);
    }
  };

  return (
    <header>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.column__image}>
            <img src="assets/ThreeStars.svg" alt="visco logo" />
            <p>Te ayudamos a crecer, escalar y mejorar tu presencia digital con el mejor stack tecnológico.</p>
          </div>

          <div className={styles.column__buttons}>
          </div>
        </div>
      </div>
    </header>
  );
}
