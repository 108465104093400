import styles from './legalterms.module.css';
import { useState, useEffect } from 'react';

export default function LegalTerms() {
  const PopUp = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className={styles.overlay}>
        <div className={styles.content}>
          <div className={styles.content__images}>
            <img src="assets/ThreeStars.svg" alt="visco logo" />
            <img src="assets/Cross.svg" alt="literaly a cross" className={styles.overlay__closer} onClick={onClose} />
          </div>
          <div className={styles.content__text}>
            <h1>AVISO LEGAL</h1>
            <br />

            <h2>Objeto</h2>
            <br />
            <p>
              El presente aviso legal regula el uso y utilización del sitio web www.visco.uno del que es titular
              VISIONARY CONSULTING, S.L.
            </p>
            <br />
            <p>
              La navegación por el sitio web le atribuye la condición de USUARIO de este y conlleva su aceptación plena
              y sin reservas de todas y cada una de las condiciones publicadas en este aviso legal, advirtiendo de que
              dichas condiciones podrán ser modificadas sin notificación previa por parte de VISIONARY CONSULTING, S.L.,
              en cuyo caso se procederá a su publicación y aviso con la máxima antelación posible.
            </p>
            <br />
            <p>
              Por ello es recomendable leer atentamente su contenido en caso de desear acceder y hacer uso de la
              información y de los servicios ofrecidos desde este sitio web.
            </p>
            <br />
            <p>
              El usuario, además, se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena
              fe, el orden público, los usos del tráfico y el presente Aviso Legal, y responderá frente a VISIONARY
              CONSULTING, S.L. o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como
              consecuencia del incumplimiento de dicha obligación.
            </p>
            <br />
            <p>
              Cualquier utilización distinta a la autorizada está expresamente prohibida, pudiendo VISIONARY CONSULTING,
              S.L. denegar o retirar el acceso y su uso en cualquier momento.
            </p>
            <br />
            <br />
            <br />
            <br />

            <h2>1. Identificación</h2>
            <br />
            <p>
              VISIONARY CONSULTING, S.L., en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad
              de la Información y de Comercio Electrónico, le informa de que:
            </p>
            <br />
            <ul>
              <li>
                <b>Denominación social:</b> VISIONARY CONSULTING, S.L.
              </li>
              <li>
                <b>Nombre comercial:</b> VISCO
              </li>
              <li>
                <b>CIF:</b> B19362235
              </li>
              <li>
                <b>Domicilio social:</b> C/ Venecia 24 (Local Derecha) | 50007 Zaragoza
              </li>
              <li>
                <b>Inscripción en el Registro Mercantil:</b> Registro Mercantil de Zaragoza · 1/2024/2.932 Folio: 451
                F.P.: 22/08/2024
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />

            <h2>2. Comunicaciones</h2>
            <br />
            <p>
              Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a
              continuación:
            </p>
            <br />
            <ul>
              <li>
                <b>Teléfono:</b> 622 42 74 41
              </li>
              <li>
                <b>Email:</b> hola@visco.uno
              </li>
              <li>
                <b>Dirección postal:</b> C/ Venecia 24 (Local Derecha) | 50007 Zaragoza
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />

            <h2>3. Condiciones de acceso y utilización</h2>
            <br />
            <p>
              El sitio web y sus informaciones son de acceso libre y gratuito. No obstante, Visionary Consulting puede
              condicionar la utilización de algunos de los servicios ofrecidos en su web a la previa formalización del
              correspondiente contrato de prestación de servicios bajo licencias.
            </p>
            <br />
            <p>
              El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a Visionary
              Consulting y será el único responsable de las manifestaciones falsas o inexactas que realice.
            </p>
            <br />
            <p>
              El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de Visionary
              Consulting y a no emplearlos para, entre otros:
            </p>
            <br />
            <ul>
              <li>
                Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología
                del terrorismo o, en general, contrarios a la ley o al orden público.
              </li>
              <li>
                Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear,
                interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos
                de Visionary Consulting o de terceras personas.
              </li>
              <li>
                Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los
                sistemas informáticos de Visionary Consulting o de terceros y, en su caso, extraer información.
              </li>
              <li>
                Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la
                información de Visionary Consulting o de terceros.
              </li>
              <li>Suplantar la identidad de cualquier otro usuario.</li>
              <li>
                Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase sin que medie su
                previa solicitud o consentimiento.
              </li>
            </ul>
            <br />
            <p>
              Todos los contenidos del sitio web constituyen una obra cuya propiedad pertenece a Visionary Consulting
              sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos.
            </p>
            <br />
            <br />
            <br />
            <br />

            <h2>3.1 Exclusión de garantías y de responsabilidad</h2>
            <br />
            <p>
              Visionary Consulting excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por
              los daños y perjuicios derivados de:
            </p>
            <br />
            <br />
            <br />

            <ul>
              <li>
                La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud o vigencia de los contenidos.
              </li>
              <li>
                La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los
                sistemas informáticos o datos de los usuarios.
              </li>
              <li>
                El incumplimiento de las leyes, la buena fe, el orden público o el presente aviso legal como
                consecuencia del uso incorrecto del sitio web.
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />

            <h2>3.2 Procedimiento en caso de realización de actividades ilícitas</h2>
            <br />
            <p>
              En caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el
              carácter ilícito de la utilización de cualquier contenido, deberá enviar una notificación a Visionary
              Consulting identificándose debidamente.
            </p>
            <br />
            <br />
            <br />
            <br />

            <h2>3.3 Publicaciones</h2>
            <br />
            <p>
              La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las
              leyes y disposiciones generales que deben ser publicadas formalmente.
            </p>
            <br />
            <p>
              Los interesados tienen derecho a acceder a sus datos personales y a solicitar la rectificación o supresión
              de los datos cuando ya no sean necesarios.
            </p>
            <br />
            <p>
              En determinadas circunstancias, podrán solicitar la limitación del tratamiento de sus datos o su
              portabilidad.
            </p>
            <br />
            <p>
              Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirarlo en cualquier
              momento sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
            </p>
            <br />
            <p>
              Si considera que sus derechos han sido vulnerados, puede presentar una reclamación ante la Autoridad de
              Control en materia de Protección de Datos competente a través de su sitio web: www.agpd.es.
            </p>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  /* CLOSE POPUP PRESSING ESC KEY */

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClosePopup();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <div>
        <p className={styles.overlay__trigger} onClick={handleOpenPopup}>
          Términos legales
        </p>
        <PopUp show={showPopup} onClose={handleClosePopup} />
      </div>
    </>
  );
}
